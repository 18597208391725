import React, { useContext, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { MainContext } from "context/context"
import { isActiveLink } from 'utils'
import { navigate } from 'gatsby'
import {Link,  animateScroll as scroll } from "react-scroll"
import Select from 'react-select'
import 'vendor/hamburgers.css'
import { isMobile } from 'utils'

export default ({
	location
}) => {
	const [isLoading, setIsLoading] = useState(true)

    const { siteFields } = useStaticQuery(graphql`
        query {
            siteFields {
				pages {
					nodes {
						slug
						title
					}
				}
                menu (
					id: "TWVudTo0"
				) {
					menuItems {
						nodes {
							label
							title
							url
							childItems {
								nodes {
									label
									title
									url	
								}
							}
						}
					}
				}
            }
        }
	`)

	const { nodes: pages } = siteFields.pages

	const selectOptions = pages.reverse().map(page => {
		return {
			value: page.slug,
			label: page.title
		}
	})

	const {
		options,
		isHeaderInverted,
		hasScrolled,
		checkInvertedHeaderOffsets,
		closeModal,
		modalVisible,
	} = useContext(MainContext)

	const {
		logos
	} = options

	useEffect(() => {
		setTimeout(() => {

			setIsLoading(false)
		},400)
	}, [])

	useEffect(() => {
		setTimeout(() => {
			checkInvertedHeaderOffsets()
		},1000)
	}, [location])

	const [selectedLanguage, setSelectedLanguage] = useState((() => {
		const pathSlug = location.pathname.replace('/','')

		let selectedIndex = 0

		if(pathSlug){
			selectedIndex = selectOptions.findIndex(option => {
				return option.value === pathSlug
			})
		}

		return selectOptions[selectedIndex]
	})())

	const [selectedChildItems, setSelectedChildItems] = useState([])
	const [hoveredMenuItemIndex, setHoveredMenuItemIndex] = useState(-1)

	let headerClassName = 'header'

	if(isHeaderInverted){
		headerClassName += ' inverted'
	} 

	if(hasScrolled){
		headerClassName += ' has-scrolled'
	} 

	if(modalVisible) {
		headerClassName += ' modal-visible'
	}

	const handleHover = (index, items = []) => {
		if(items.length){
			setSelectedChildItems(items)
			setHoveredMenuItemIndex(index)
		}else{
			setHoveredMenuItemIndex(-1)
		}
	}

	let headerContainerName = 'header-container'


	
	const handleSelectChange = value => {
		setSelectedLanguage(value)
		navigate(value.value == 'en' ? '/' : value.value)
	}

	const loadingOverlayClassName = isLoading ? 'loading-overlay visible' : 'loading-overlay'

	return (
		<>
		<div 
			className={headerContainerName}
			onMouseLeave={() => handleHover(-1)}
		>
			<header className={headerClassName} id="header">
				<div className="container">
					<div className="content">
						<Link
							to={'Hero'} 
							className={'logo'}
							spy={true}
							smooth={true}
							offset={-82}
							duration={500}
						>
							<img className="normal" src={logos.standardBlack.sourceUrl} />
							<img className="inverted" src={logos.brandmarkBlack.sourceUrl} />
						</Link>
						{isMobile &&
							<a className="close" onClick={closeModal}>
								<i className="fe fe-x"></i>
							</a>
						}
					</div>
				</div>
				<Link
					className={'button'}
					activeClass="active"
					to="FormSection"
					spy={true}
					smooth={true}
					offset={-82}
					duration={500}
				>
					Register your interest
				</Link>
			</header>
			<div className={`header-sub-menu ${ hoveredMenuItemIndex !== -1 ? 'visible' : ''}`}>
				<ul>
					{ selectedChildItems.map((item, index) => {
						const {
							label,
							url
						} = item

						return (
							<li key={index}>
								<Link className={isActiveLink(location, url) ? 'active' : ''} to={url}>
									{ label }
								</Link>
							</li>
						)
					})}
				</ul>
			</div>
		</div>
		<div className={loadingOverlayClassName}>
			<img src={require('../images/loading.svg')} />
		</div>
		</>
	)
}
