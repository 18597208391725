import React, {useContext} from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Link from 'components/link'
import { MainContext } from "context/context"

export default () => {
    const {
        options,
        currentPage
    } = useContext(MainContext)

    const {
        logos,
        footer,
    } = options

    console.log('footer log:')
    console.log(footer)

    return (
        <footer>
            <div className="container">
                <div className="column right">
                    <ul className="columns">
                        {footer.partners.map((column, index) => {
                            return (
                                <li key={index}>
                                    <a href={column.website} target="_blank">
                                        <img src={column.logo.mediaItemUrl}/>
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className="column left">
                    <img className="logo desktop" src={logos.brandmarkWhite.sourceUrl}/>
                    <img className="logo mobile" src={logos.standardWhite.sourceUrl}/>
                    <div className="copy" dangerouslySetInnerHTML={{__html: footer.copy}}></div>
                </div>
            </div>
        </footer>
    )
}
