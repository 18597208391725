import React, { useContext, useEffect } from 'react'
import { MainContext } from "context/context"
import Header from './header'
import Footer from './footer'
import Modal from './modal'

export default ({ children, location }) => {
	const href = location ? location.href : 'nothing'

	const {
		options,
	} = useContext(MainContext)

	const {
		logos
	} = options

	return (
		<div className={`page-container lang-${ location.pathname.replace('/','') } loc-${ href }`}>
			<Header location={location} />
			<main>
				{ children }
			</main>
			<Footer />
			<Modal />
		</div>
  	)
}