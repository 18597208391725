import React, { useRef, useEffect, useState } from 'react'
import Slider from 'components/slider'
import 'vendor/flickity-as-nav-for/as-nav-for'
import 'flickity/css/flickity.css'
import { isMobile } from 'utils'

export default ({
    property,
    closeModal
}) => {

    const {
        propertyFields: {
            gallerySlides
        }
    } = property

    const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

    const mainSliderRef = useRef(null)
    const thumbSliderRef = useRef(null)

    const sliderJsx = gallerySlides && gallerySlides.map((slide, index) => {
        const backgroundUrl = slide.image.mediaItemUrl 

        return (
            <div
                className="slide"  
                key={index}
            >
                <div
                    className="image"
                    style={{
                        backgroundImage: `url(${ backgroundUrl })`
                    }}
                />
                <div
                    className="copy"
                    dangerouslySetInnerHTML={{__html: slide.text}}
                />
            </div>

        )
    })

    const totalNumSlides = gallerySlides.length - 1

    const thumbClick = (index) => {
        mainSliderRef.current.select(index)
        thumbSliderRef.current.select(index)
    }

    const thumbJsx = gallerySlides && gallerySlides.map((slide, index) => {

        return (
            <div
                className="slide"  
                key={index}
                onClick={() => {thumbClick(index)}}
            >
                <div className="image" style={{backgroundImage: `url('${slide.image.mediaItemUrl}')`}}/>
            </div>

        )
    })
  
    const buttonHandler = command => {
        if(command === 'next') {
            thumbSliderRef.current.next()
        } else {
            thumbSliderRef.current.previous()
        }
    } 

    useEffect(() => {
        if(mainSliderRef.current){
            mainSliderRef.current.on('change', index => {
                if(thumbSliderRef.current){
                    thumbSliderRef.current.select( index )
                    setCurrentSlideIndex(index)
                }
            })
        }
        if(thumbSliderRef.current){
            thumbSliderRef.current.on('change', index => {
                if(mainSliderRef.current){
                    mainSliderRef.current.select( index )
                }
            })
        }
    })

    return (
        <div className="modal-container">
            {!isMobile && 
                <a className="close" onClick={closeModal}>
                    <i className="fe fe-x"></i>
                </a>
            }
            <div className="main-slider">
                { typeof window != 'undefined' &&
                    <Slider
                        className={'carousel'}
                        options={{
                            wrapAround: false,
                            groupCells: 1,
                            infinite: true,
                            prevNextButtons: false,
                            pageDots: false,
                            cellAlign: 'left',
                            adaptiveHeight: true,
                            contain: true,
                        }}
                        flickityRef={ref => mainSliderRef.current = ref}
                    >
                        {sliderJsx}
                    </Slider>
                }
            </div>
            <div className="thumb-slider">
                {currentSlideIndex != 0 &&
                    <a className="arrow previous" onClick={() => {buttonHandler('previous')}}>
                        <i className="fe fe-arrow-left"></i>
                    </a>
                }
                {currentSlideIndex != totalNumSlides &&
                    <a className="arrow next" onClick={() => {buttonHandler('next')}}>
                        <i className="fe fe-arrow-right"></i>
                    </a>
                }
                { typeof window != 'undefined' &&
                    <Slider
                        className={'carousel'}
                        options={{
                            wrapAround: false,
                            groupCells: 1,
                            infinite: true,
                            prevNextButtons: false,
                            pageDots: false,
                            cellAlign: 'left',
                            contain: true,
                        }}
                        flickityRef={ref => thumbSliderRef.current = ref}
                    >
                        {thumbJsx}
                    </Slider>
                }
            </div>
        </div>
    )
}