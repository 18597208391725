import { useState, useEffect } from 'react'

import moment from 'moment'

export * from './arrays'

export const formatDate = date => {
    return moment(date).format('MMMM Do, YYYY')
}

export const isActiveLink = (location, url) => {
    const { pathname } = location

    const _url = url.replace('/','')
    const _pathname = pathname.replace('/','')
    const isActive = _pathname && _pathname.indexOf(_url) !== -1

    return isActive
}

export const nl2br = str => {
    if (typeof str === 'undefined' || str === null) {
        return ''
    }

    const breakTag = '<br />'
    
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

export const isMobile = typeof window !== 'undefined' ? window.innerWidth <= 500 : false

export const emailIsValid = email => {
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase())
}

export const useIsMobile = () => {
    const [_isMobile, setIsMobile] = useState(false)

    const _window = typeof window !== 'undefined' ? window : null

    useEffect(() => {
        setTimeout(() => {
            if(_window){
                setIsMobile(_window.innerWidth <= 500)
            }
        },1)
    },[_window])

    return _isMobile
}